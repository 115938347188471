module.exports = {
  popup_hints: {
    settings_button: 'Настроить',
    delete_button: 'Удалить',
    recalculate_metric: 'Пересчитать метрики',
    templates_list_count: 'Количество шаблонов в группе',
    settings_template_icon: 'Настроить шаблон ',
    delete_template_icon: 'Удалить шаблон',
    copy_template_icon: 'Копировать шаблон',
    copy_id: 'Копировать ID'
  },
  system: {
    err403: 'Метрика не может быть удалена, т.к. она используется в составной метрике либо ручной метрике.'
  },
  cant_create_group: 'Невозможно создать группу без имени',
  add_metric_template: 'Добавить шаблон метрики',
  metric_templates: 'Шаблоны метрик',
  group_name: 'Название группы',
  new_group: 'Новая группа',
  recalculate: 'Пересчитать для всех записей',
  recalculate_manual_metric_only: 'Пересчитать только для записей с изменениями в ручной метрике',
  all: 'Все',
  none_settings: 'Нет настроек',
  first_secs: 'Первые N секунд',
  last_secs: 'Последние N секунд',
  pause_duration: 'Порог длительности паузы',
  warning: 'Предупреждение',
  metric: 'Метрики',
  add_metric: 'Добавить метрику',
  group_delete_confirm: 'Вы действительно хотите удалить данную группу?',
  metric_delete_confirm:
    'Удаление метрики повлияет на работу данного аналитического проекта и отчётов в нём. Вы действительно хотите удалить данную метрику?',
  table: {
    name: 'НАЗВАНИЕ',
    type: 'ТИП МЕТРИКИ',
    channel: 'КАНАЛ ПОИСКА',
    settings: 'НАСТРОЙКИ МЕТРИКИ',
    actions: 'Действия',
  },
  meta_metrics: 'Мета метрики',
  vad_metrics: 'VAD метрики',
  chat_metrics: 'CHAT метрики',
  speech_metrics: 'SPEECH метрики',
  other_metrics: 'Other',
  help: 'Подсказка',
  add: 'Добавить',
  cancel: 'Отменить',
  add_template: 'В список шаблонов метрик будет добавлена метрика с именем',
  fill_all: 'Заполните все поля',
  metric_group: 'Группа метрик',
  metric_id: 'ID метрики:',
  project_id: 'ID проекта',
  copy_hint: 'Копировать ID',
  one_metric: 'метрика',
  metric_templates_group: 'Группа шаблонов метрик',
  choose: 'Выберите',
  channel_search: 'Канал поиска',
  classifier_model: 'Модель классификатора',
  classifier_theme: 'Тема классификатора',
  probably: "Вероятность",
  summ_silent: 'Единица измерения суммарной длительности тишины',
  threshold: 'Порог длительности паузы, (сек.)',
  save: 'Сохранить',
  save_as_template: 'Сохранить как шаблон',
  all_words: 'Учитывать все словоформы',
  color: 'Цвет подсветки',
  alias: 'Алиас метаданных',
  input: 'Ввод',
  secs: 'Секунды',
  role: 'Роли',
  metric_create: 'Создание метрики',
  clear_all: 'Стереть все',
  from_template: 'Выбрать из списка шаблонов',
  from_template_add: 'Добавить метрику из шаблона',
  add_new_template: 'Добавить новый шаблон метрики',
  add_new_metric: 'Добавить новую метрику',
  metric_create_new: 'Создать новую метрику',
  metric_name: 'Название метрики',
  metric_description: 'Описание метрики',
  exclude_begin_sec: 'Исключить из анализа первые N секунд',
  exclude_end_sec: 'Исключить из анализа последние N секунд',
  search_begin_sec: 'Искать в первых N секундах',
  search_end_sec: 'Искать в последних N секундах',
  relations: 'Связанные метрики',
  relations_after: 'Искать после',
  relations_before: 'Искать до',
  exclude_begin_messages: 'Исключить из анализа первые N фраз',
  exclude_end_messages: 'Исключить из анализа последние N фраз',
  summ_value: 'Единица измерения суммарной длительности речи',
  meta: 'Перенос значений метаданных в метрики, чтобы эти данные можно было использовать в отчётах.',
  summ_speech_duration:
    'Показывает сколько времени говорил выбранный спикер. Учитывается время чистого разговора без пауз.',
  summ_silent_duration: 'Показывает длительность всех пауз в выбранном канале в течении записи.',
  interruptions:
    'Показывает количество эпизодов перебивания. Перебить - начать говорить, когда говорит кто-то другой. При этом происходит наложение речи, т.е. одновременный разговор двух и более спикеров.',
  pause_exceed:
    'Показывает количество пауз, которые превышают указанную в настройках метрики длительность.',
  avg_pause_duration: 'Показывает среднюю длительность пауз в выбранном канале.',
  max_pause_duration: 'Показывает длительность самой долгой паузы в выбранном канале.',
  find_words:
    'Показывает количество найденных совпадений с заданным набором слов/фраз в заданном канале.',
  max_monolog_duration: 'Показывает длительность самого долгого участка речи выбранного спикера.',
  tags_metric: 'Позволяет вручную проставить значения для записей. Значения можно указывать в свободной форме или выбирать из заданного списка.',
  speech_rate_duration:
    'Позволяет измерить в каком темпе говорят собеседники. В результатах метрики для записи указывается средняя скорость речи в выбранном канале.',
  calculated:
    'Показывает результат рассчитанный по заданной формуле. Результат может быть как в числовом, так и в текстовом виде. При расчёте используются данные других метрик. Данная метрика рассчитывается только после того, как будут рассчитаны все остальные метрики в рамках данного Аналитического Проекта.',
  success: 'Успешно',
  error: 'Не удалось',
  recalculate_modal:'Внимание! Пересчёт метрик производится только при статусе проекта "Активен".' +
    'Будут пересчитаны все метрики по указанным ниже записям, кроме значений “Ручных метрик“, измененных пользователями.' +
    'Данный процесс может занять продолжительное время. До окончания пересчёта метрики проекта будут обнулены',
  group_delete: 'Вы действительно хотите удалить данную группу?',
  template_delete: 'Вы действительно хотите удалить данный шаблон?',
  metric_template: 'Показывает длительность самой долгой паузы между фразами в чате.',
  c_find_words: 'Показывает количество найденных совпадений с заданным набором слов/фраз в заданном канале.',
  c_first_answer_duration: 'Показывает время между первым сообщением в чате и следующим за ним сообщением от другого участника.',
  c_avg_pause_duration: 'Показывает средняя длительность пауз между фразами в чате.',
  c_spell_check: 'Показывает количество найденных орфографических ошибок у выбранной роли.',
  c_num_words_message: 'Показывает количество слов в самом длинном сообщении у выбранной роли.',
  c_characters: "Показывает количество всех символов в чате у выбранной роли.",
  c_pause_exceed: "Показывает количество пауз между фразами в чате, которые превышают указанную в настройках метрики длительность.",
  manualMetric: {
    number: 'Число',
    text: 'Текст',
    manual_entry: 'Ручной ввод',
    select_from_list: 'Выбор из списка',
    pictogram: 'Пиктограмма',
    value: 'Значение',
    both: 'Оба',
    toast_edit_success_message: 'Метрика отредактирована',
    toast_edit_error_message: 'Не удалось отредактировать метрику',
    toast_create_success_message: 'Метрика добавлена',
    toast_create_error_message: 'Не удалось добавить метрику',
    toast_update_success_message: 'Значение обновлено',
    toast_update_error_message: 'Не удалось обновить значение',
    available_variant_list: 'Список доступных вариантов',
    allow_multiple_selection: 'Разрешить множественный выбор',
    display_type: 'Тип отображения',
    by_default: 'По умолчанию',
    field_required: 'Поле не заполнено',
    bad_format: 'Неверный формат, символы запрещены',
    placeholder: 'Введите значение',
    add_field_to_search: 'Добавить строку в список',
    value_by_default: 'Значение по умолчанию',
    or: 'или',
    value_from_metric: 'Значение из метрики',
    metric_name: 'Название метрики',
    name: 'Название',
    value_type: 'Тип значения',
    input_method: 'Способ ввода',
    description: 'Описание',
    fill_metric_details: 'Опишите метрику',
    save: 'Сохранить',
    cancel: 'Отмена'
  }
};
