import { CircleButton, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import { TemplateListIcon } from '@app/components/ui/icons/icons-list';
import RubberTable from 'src/components/rubber-table';
import { TableItem } from '@app/components/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { MetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';
import React from 'react';
import { v4 } from 'uuid';
import {
  AllChanelsArrType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';
import { useDeleteMetricTemplateMutation } from '@app/store/api/metric-template.api';
import { EasyModal } from '@ui';
import { metricIconsData } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/data/data';
import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

type PropsType = {
  loading?: boolean;
  groupName: string;
  data: MetricTemplatesType[];
  metricTypes: MetricTypesArrType[] | undefined;
  allChannels: AllChanelsArrType[] | undefined;
  dictionaries: DictionariesType[] | undefined;
  getMetricTemplateSettingsHandler(metric_id: string, type: string, mode: string): void;
  chatRoles?: ChatRoleSettingsType[];
};

export const MetricTemplateTable: React.FC<PropsType> = ({
  data,
  loading,
  groupName,
  metricTypes,
  allChannels,
  chatRoles,
  dictionaries,
  getMetricTemplateSettingsHandler,
}) => {
  const { t } = useTranslation('pages.metricTemplates');
  const [deleteMetricModal, setDeleteMetricModal] = React.useState<MetricTemplatesType | null>(
    null,
  );
  // api
  const [deleteMetricTemplate] = useDeleteMetricTemplateMutation();
  const deleteSingleMetricTemplate = () => {
    if (deleteMetricModal?.metric_template_id) {
      deleteMetricTemplate({ id: deleteMetricModal?.metric_template_id }).then(() => {
        setDeleteMetricModal(null);
      });
    }
  };

  const clickCircleDelete = (metric: MetricTemplatesType) => {
    setDeleteMetricModal(metric);
  };

  const circleButtonClick = (metric_id: string, type: string, mode: string) => {
    getMetricTemplateSettingsHandler(metric_id, type, mode);
  };

  const getChannelNameById = (id: string) => {
    if (id !== 'all') {
      return allChannels?.find((item) => item.settings_channels_id === id)?.name;
    } else {
      return t('all');
    }
  };

  const getTypeName = (type: string) => {
    return metricTypes?.find((item) => item.type === type)?.name;
  };

  function getRoleNameById(id: string) {
    return chatRoles?.find((role) => role.crole_id === id)?.name;
  }

  const getDictionaryNameById = (id: string) => {
    return dictionaries?.find((e) => e.dictionary_id === id)?.name;
  };
  const getMetricSetting = (dataItem: MetricTemplatesType) => {
    if (dataItem.type === 'find-words') {
      if (
        dataItem.settings.wordlist?.length !== 0 ||
        dataItem.settings.dictionaries?.length !== 0
      ) {
        return (
          <div className="truncate max-h-[100px] flex flex-wrap items-center gap-[6px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px] w-fit max-w-[400px]">
            {dataItem.settings.dictionaries?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-[#FFE2F4] text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {getDictionaryNameById(e)}
                </span>
              );
            })}
            {dataItem.settings.wordlist?.map((words, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-basic_app_bg text-0color text-[13px] rounded-[4px]"
                  key={words.id + index}
                >
                  {words.full_word}
                </span>
              );
            })}
          </div>
        );
      } else return t('none_settings');
    } else {
      return (
        <div className="max-w-[400px] h-full flex flex-wrap items-center gap-[12px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px]">
          <span className="text-[10px] text-0color">
            {t('first_secs') + ': ' + dataItem.settings.exclude_begin_sec}
          </span>
          <span className="text-[10px] text-0color">
            {t('last_secs') + ': ' + dataItem.settings.exclude_end_sec}
          </span>
          {dataItem.settings.threshold || dataItem.settings.threshold === 0 ? (
            <span className="text-[10px] text-0color">
              {t('pause_duration') + ': ' + dataItem.settings.threshold}
            </span>
          ) : null}
        </div>
      );
    }
  };

  const filteredArr = () => {
    return data?.map((dataItem) => ({
      ...dataItem,
      utils: (
        <div className="w-full gap-[10px] pl-[10px] flex items-start border-l-[1px] border-b-light">
          <CircleButton
            dataId={`metric-template-${groupName}-${dataItem.name}-configure`}
            hintTitle={t('popup_hints.settings_template_icon')}
            onClick={() => {
              circleButtonClick(dataItem.metric_template_id, dataItem.type, '');
            }}
            icon="SettingIcon"
            size={18}
            className={`text-3color hover:text-action`}
          />
          <CircleButton
            dataId={`metric-template-${groupName}-${dataItem.name}-copy`}
            hintTitle={t('popup_hints.copy_template_icon')}
            onClick={() => {
              circleButtonClick(dataItem.metric_template_id, dataItem.type, 'copy');
            }}
            icon="CopyIcon"
            size={18}
            className={`text-3color hover:text-action`}
          />
          <CircleButton
            dataId={`metric-template-${groupName}-${dataItem.name}-delete`}
            hintTitle={t('popup_hints.delete_template_icon')}
            icon="TrashIcon"
            size={18}
            onClick={() => clickCircleDelete(dataItem)}
            className={` text-basic_red`}
          />
        </div>
      ),
      channel: (
        <div className="text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          <div>
            {dataItem.settings.channel && getChannelNameById(dataItem.settings.channel)}
            {dataItem.settings.c_role && getRoleNameById(dataItem.settings.c_role)}
          </div>
        </div>
      ),
      metricSettings: (
        <div className="py-[5px] text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          {getMetricSetting(dataItem)}
        </div>
      ),
      type: (
        <div className="w-full text-0color font-[400] tracking-tight text-[14px] leading-[110%] flex items-center gap-[10px]">
          <Icon
            size={28}
            name={metricIconsData[dataItem.type.replace(/([.\-\\/ ])/g, '_')] || 'HelpCircleIcon'}
          />
          <div className="truncate">{getTypeName(dataItem.type)}</div>
        </div>
      ),
      name: (
        <div className="text-0color font-[600] tracking-tight text-[14px] leading-[110%] flex items-center">
          <div className="min-h-[28px] min-w-[28px] max-h-[28px] max-w-[28px] flex items-center justify-center ml-[16px] mr-[12px] bg-blue_pal rounded-[6px]">
            <TemplateListIcon size={13} className="text-action" />
          </div>
          <TooltipWrapper content={dataItem.name} id={dataItem.name + v4()}>
            <div className="truncate" data-id={`metric-template-${groupName}-${dataItem.name}`}>
              {dataItem.name}
            </div>
          </TooltipWrapper>
        </div>
      ),
    })) as unknown as Array<TableItem>;
  };
  const columns = [
    {
      title: t('table.name'),
      index: 'name',
    },
    {
      title: t('table.type'),
      index: 'type',
    },
    {
      title: t('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: t('table.settings'),
      index: 'metricSettings',
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];

  return (
    <>
      <EasyModal
        dataId={`${deleteMetricModal?.name}-delete-confirm`}
        show={!!deleteMetricModal}
        onClose={setDeleteMetricModal.bind(null, null)}
        label={
          <div className="px-[5px] w-full break-words" title={deleteMetricModal?.name}>
            {t('warning')} <br /> {deleteMetricModal?.name}{' '}
          </div>
        }
        variant="removal"
        onRemove={() => deleteSingleMetricTemplate()}
        withoutFooter
      >
        <div className="px-4 text-center">{t('template_delete')}</div>
      </EasyModal>

      <RubberTable withoutCols isPending={loading} columns={columns} dataSource={filteredArr()} />
    </>
  );
};
