import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, CircleButton, EasyModal, Input, Paginator, Toggle, TooltipWrapper } from '@ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
import { emailRegexp } from '@app/utils/regexp';
import {
  MixedTriggerRecipientsType,
  TriggerPlatformType,
} from '@app/interfaces/metric-trigger.type';
import { MixedRecipientsRequestType } from '@app/interfaces/tenant-recipients.type';
import RubberTable from '@app/components/rubber-table';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { TableFilter } from '@app/components/table/table.type';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';

type AddRecipientsFormPropsType = {
  onClose: () => void;
  hideAddRecipientsFeature?: boolean;
  subscribeDisable?: boolean;
  onlyForCreate?: boolean;
  activePlatformFromParent?: TriggerPlatformType;
  filterParams?: MixedRecipientsRequestType;
  editableRecipientData?: {
    description: string;
    recipient_id: string;
  };
  editMode?: boolean;
  changeFilterParams?: React.Dispatch<React.SetStateAction<MixedRecipientsRequestType>>;
  handleChangeRecipientStatus?(
    status: boolean,
    platform: TriggerPlatformType,
    recipient_id: string,
  ): void;
  triggerSubscribedRecipients?: MixedTriggerRecipientsType;
  changeTriggerSubscribedRecipients?: React.Dispatch<
    React.SetStateAction<MixedTriggerRecipientsType>
  >;
  tenantRecipients?: {
    telegram: {
      recipients:
        | {
            description: string;
            recipient_id: string;
            confirmed: boolean;
          }[]
        | undefined;
      total: number | undefined;
    };
    email: {
      recipients:
        | {
            description: string;
            email: string | undefined;
            confirmed: boolean;
            recipient_id: string;
          }[]
        | undefined;
      total: number | undefined;
    };
  };
  onEditRecipientDescription?(body: { recipient_id: string; description: string }): void;
  onCreateNewRecipient(
    platform: 'telegram' | 'email',
    body: {
      email: string;
      description: string;
    },
  ): Promise<{ status: boolean; recipient_id?: string; hash?: string }> | undefined;
};

const recipientsFormsByPlatform = {
  email: 'email',
  telegram: 'telegram',
};
const AddRecipientsForm: FC<AddRecipientsFormPropsType> = (props) => {
  const {
    onCreateNewRecipient,
    changeTriggerSubscribedRecipients,
    tenantRecipients,
    triggerSubscribedRecipients,
    handleChangeRecipientStatus,
    filterParams,
    changeFilterParams,
    hideAddRecipientsFeature = false,
    onlyForCreate = false,
    activePlatformFromParent,
    editMode,
    subscribeDisable,
    editableRecipientData,
    onEditRecipientDescription,
    onClose,
  } = props;
  const { t } = useTranslation('pages.triggers.recipients_form');
  const { t: table } = useTranslation('pages.settings.messages_managing');
  const { t: recipientsTranslation } = useTranslation('components.recipientsModal');
  const [telegramHash, setTgHash] = useState<string>();
  const [emailValue, changeEmailValue] = useState<string>('');
  const [descriptionValue, changeDescriptionValue] = useState<string>('');
  const [createdRecipientIndex, changeCreatedRecipientIndex] = useState<string | undefined>(
    onlyForCreate ? 'true' : undefined,
  );
  const [activePlatform, changeActivePlatform] = useState<TriggerPlatformType>(
    activePlatformFromParent || 'email',
  );

  const fieldsValidateStatus = useMemo(() => ({ description: true, email: true }), []);
  useEffect(() => {
    if (!editMode) return;
    fieldsValidateStatus['description'] = false;
    fieldsValidateStatus['email'] = false;
    editableRecipientData?.description &&
      changeDescriptionValue(editableRecipientData?.description);
  }, [editMode, editableRecipientData?.description, fieldsValidateStatus]);

  const activePlatformRecipient = Object.entries(triggerSubscribedRecipients || {}).reduce<
    Record<TriggerPlatformType, string[]>
  >(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.map(({ recipient_id }) => recipient_id),
    }),
    { email: [], telegram: [] },
  );

  function handleChangeActiveRecipient(status: boolean, recipient_id: string) {
    const recipientId =
      triggerSubscribedRecipients?.[activePlatform].find((i) => i.recipient_id === recipient_id)
        ?.trigger_recipient_id || '';

    handleChangeRecipientStatus?.(status, activePlatform, status ? recipient_id : recipientId);
  }
  function handleRemoveRecipient() {
    if (!createdRecipientIndex) return;
    changeCreatedRecipientIndex(undefined);
  }
  function handleAddNewRecipient() {
    changeCreatedRecipientIndex(String(tenantRecipients?.[activePlatform]?.recipients?.length));
  }
  function handleApproveNewRecipient(body: { email: string; description: string }) {
    if (activePlatform === 'telegram' && fieldsValidateStatus.description) return;
    if (
      activePlatform === 'email' &&
      (fieldsValidateStatus.email || fieldsValidateStatus.description)
    )
      return;
    editMode && editableRecipientData?.recipient_id
      ? onEditRecipientDescription?.({
          description: body.description,
          recipient_id: editableRecipientData?.recipient_id,
        })
      : onCreateNewRecipient(activePlatform, body)?.then((data) => {
          if (data.status) {
            if (data.hash) {
              setTgHash(data.hash);
            }
            if (!createdRecipientIndex || !changeTriggerSubscribedRecipients) return;
            data.recipient_id && activePlatform === 'telegram'
              ? changeTriggerSubscribedRecipients((prev) => ({
                  ...prev,
                  telegram: [
                    ...prev.telegram,
                    { recipient_id: data.recipient_id || '', trigger_recipient_id: '' },
                  ],
                }))
              : changeTriggerSubscribedRecipients((prev) => ({
                  ...prev,
                  email: [
                    ...prev.email,
                    { recipient_id: data.recipient_id || '', trigger_recipient_id: '' },
                  ],
                }));
            changeCreatedRecipientIndex(undefined);
          }
        });
    changeDescriptionValue('');
    changeEmailValue('');
  }

  const columns: TableColumn[] =
    activePlatform === 'telegram'
      ? [
          { title: table('description'), filter: true, index: 'description' },
          { title: table('confirmed'), filter: true, index: 'confirmed' },
          { title: table('subscribe'), index: 'action', divider: 'left', maxWidth: 100 },
        ]
      : [
          { title: table('description'), filter: true, index: 'description' },
          { title: table('email'), filter: true, index: 'email' },
          { title: table('confirmed'), filter: true, index: 'confirmed' },
          { title: table('subscribe'), index: 'action', divider: 'left', maxWidth: 100 },
        ];
  const tableData: TableItem[] =
    tenantRecipients?.[activePlatform]?.recipients?.map((field, index) => ({
      ...field,
      confirmed: (
        <div className={`${field.confirmed ? 'text-basic_green' : 'text-basic_red'}`}>
          {recipientsTranslation(String(field.confirmed))}
        </div>
      ),
      action: (
        <div className=" flex items-center gap-[10px]">
          {!createdRecipientIndex && (
            <div
              className={`${
                createdRecipientIndex
                  ? Number(createdRecipientIndex) === index
                    ? 'visible'
                    : 'invisible'
                  : 'visible'
              }  cursor-pointer`}
            >
              <Toggle
                disabled={subscribeDisable}
                checked={activePlatformRecipient[activePlatform].includes(field.recipient_id)}
                onChecked={(status) => handleChangeActiveRecipient(status, field.recipient_id)}
              />
            </div>
          )}
        </div>
      ),
    })) || [];
  function emailValidate(value: string) {
    if (emailRegexp.test(value.trim())) {
      fieldsValidateStatus['email'] = false;
      // changeValidateStatus((prev) => ({ ...prev, email: false }));
    } else if (!emailRegexp.test(value.trim())) {
      fieldsValidateStatus['email'] = true;
      // changeValidateStatus((prev) => ({ ...prev, email: true }));
    }
  }
  const additionalFieldForCreate = (
    <div
      className={` relative flex flex-col gap-[20px] pt-[20px] justify-between w-full h-full items-center   ${
        createdRecipientIndex
          ? 'opacity-1 transition-opacity duration-300'
          : 'opacity-0 transition-opacity duration-150'
      }`}
    >
      <div className="w-full flex items-center justify-between">
        <div className="text-1color text-[15px] font-[500] leading-[16.4px]">
          {t('description_field')}
        </div>
        <div className="w-[300px]">
          <Input
            defaultValue={descriptionValue}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length > 0) {
                fieldsValidateStatus['description'] = false;
                // changeValidateStatus((prev) => ({ ...prev, description: false }));
              } else {
                fieldsValidateStatus['description'] = true;
                // changeValidateStatus((prev) => ({ ...prev, description: true }));
              }
              changeDescriptionValue(value);
            }}
            error={fieldsValidateStatus['description'] ? t('descriptionError') : undefined}
          />
        </div>
      </div>
      {activePlatform === 'email' && !editMode && (
        <div className="w-full flex items-center justify-between">
          <div className="text-1color text-[15px] font-[500] leading-[16.4px]">{t('email')}</div>

          <div className="w-[300px]">
            <Input
              defaultValue={emailValue}
              onChange={(event) => {
                const value = event.target.value;
                changeEmailValue(value);
                emailValidate(value);
              }}
              error={fieldsValidateStatus['email'] ? t('emailError') : undefined}
            />
          </div>
        </div>
      )}
      <div className="self-start pt-[20px]">
        <Button
          onClick={() =>
            handleApproveNewRecipient({
              email: emailValue || '',
              description: descriptionValue || '',
            })
          }
          label={t('save')}
        />
        <Button onClick={onClose} fill="linked" label={t('cancel')} />
      </div>
      {false && (
        <div className="w-full pl-[20px] text-basic_red">{recipientsTranslation('false')}</div>
      )}

      {false && (
        <div className="flex items-center">
          <div className="h-[28px] w-[1px] bg-b_light"></div>
          <div className="min-w-[98px] max-w-[98px] flex items-center justify-center gap-[10px] pl-[0px]">
            <div
              className={` cursor-pointer `}
              onClick={() =>
                handleApproveNewRecipient({
                  email: emailValue || '',
                  description: descriptionValue || '',
                })
              }
            >
              <Icon size={14} name="CheckIcon" className="text-action" />
            </div>
            <div className={`cursor-pointer`} onClick={() => handleRemoveRecipient()}>
              <Icon size={14} name="TrashIcon" className="text-basic_red" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
  function changePage({ offset, limit }: PaginationResponse) {
    if (!changeFilterParams) return;
    activePlatform === 'email'
      ? changeFilterParams((prev) => ({
          ...prev,
          email: { ...prev.email, offset: offset, limit: limit },
        }))
      : changeFilterParams((prev) => ({
          ...prev,
          telegram: { ...prev.telegram, offset: offset, limit: limit },
        }));
  }
  function handleFilter(filter: TableFilter) {
    if (!changeFilterParams) return;
    const val = Object.entries(filter);
    const sortDesc = +val?.[0]?.[1] === 1;
    const sortBy = (val?.[0]?.[0] || undefined) as unknown as
      | 'description'
      | 'dt_add'
      | 'confirmed'
      | undefined;
    activePlatform === 'email'
      ? changeFilterParams((prev) => ({
          ...prev,
          email: { ...prev.email, sortBy: sortBy, sortDesc: sortDesc },
        }))
      : changeFilterParams((prev) => ({
          ...prev,
          telegram: { ...prev.telegram, sortBy: sortBy, sortDesc: sortDesc },
        }));
  }
  const addRecipientButtonMarkup: ReactNode = (
    <div
      className={`${
        !createdRecipientIndex ? 'visible' : 'invisible'
      } flex w-full h-full justify-center absolute transition-all duration-50`}
    >
      <div className="w-fit relative flex items-center">
        <div
          className={`flex items-center justify-center text-action font-bold gap-[5px] text-[15px] cursor-pointer`}
          onClick={handleAddNewRecipient}
        >
          <Icon size={18} name="PlusIcon" className="text-action" />
          {t('add_recipient_button')}
        </div>
        <div className="absolute right-[-20px] top-[12.5px]">
          <TooltipWrapper
            contentClassName="max-w-[500px]"
            place="top"
            content={
              activePlatform === 'email'
                ? recipientsTranslation('email_create_description')
                : recipientsTranslation('telegram_create_description')
            }
            id={'email_and_tg_add_new_recipient_tooltip'}
          >
            <Icon className="text-action" size={15} name="HelpCircleIcon" />
          </TooltipWrapper>
        </div>
      </div>
    </div>
  );
  // telegram: (
  //   <div
  //     className={`${
  //       !createdRecipientIndex ? 'visible' : 'invisible'
  //     } flex w-full h-full justify-center mb-4`}
  //   >
  //     <div className="w-fit relative flex items-center">
  //       <div
  //         className={`flex items-center justify-center text-action font-bold gap-[5px] text-[15px] cursor-pointer`}
  //         onClick={handleAddNewRecipient}
  //       >
  //         <Icon size={18} name="PlusIcon" className="text-action" />
  //         {t('add_recipient_button')}
  //       </div>
  //       <div className="absolute right-[-20px] top-[1px]">
  //         <TooltipWrapper
  //           contentClassName="max-w-[500px]"
  //           place="top"
  //           content={recipientsTranslation('email_create_description')}
  //           id={'email_add_new_recipient_tooltip'}
  //         >
  //           <Icon className="text-action" size={15} name="HelpCircleIcon" />
  //         </TooltipWrapper>
  //       </div>
  //     </div>
  //   </div>
  // ),
  return (
    <>
      {!onlyForCreate && (
        <div className="w-full rounded-defaultR border-b border-b_medium flex items-center justify-between">
          {Object.keys(recipientsFormsByPlatform).map((platformKey) => (
            <div
              key={platformKey}
              onClick={() =>
                !createdRecipientIndex && changeActivePlatform(platformKey as 'telegram' | 'email')
              }
              className={`${
                activePlatform === platformKey
                  ? 'cursor-default bg-gradient-to-b from-white to-gray'
                  : 'cursor-pointer'
              }  grow justify-center  pl-[18px] pr-[22px] pt-[12px] pb-[10px] flex text-base text-2color hover:text-1color rounded-t-[10px]`}
            >
              {t(platformKey)}
            </div>
          ))}
        </div>
      )}
      <div className="w-full h-full flex flex-col gap-[15px] p-4">
        <div className="h-full">
          {!onlyForCreate && (
            <RubberTable
              dataSource={tableData}
              columns={
                hideAddRecipientsFeature
                  ? columns.filter((column) => column.index !== 'confirmed')
                  : columns
              }
              onFilter={handleFilter}
            />
          )}
          {hideAddRecipientsFeature ? (
            <div className={`mb-[20px] w-full h-[46px] flex justify-center items-center`}>
              <Trans
                i18nKey={recipientsTranslation('addRecipientNavigationHelper')}
                components={{
                  manualLink: (
                    <Link
                      to={`/${navigationRoutes.settings}/${navigationRoutes.messagesManaging}`}
                      state={{ settingsRedirect: 'false' }}
                      className="underline mx-1 text-action cursor-pointer"
                    />
                  ),
                }}
              />
            </div>
          ) : (
            <div
              className={`mb-[20px] shadow-sm relative rounded-[10px] w-full h-full !bg-white flex justify-center `}
            >
              {additionalFieldForCreate}
              {!onlyForCreate && addRecipientButtonMarkup}
            </div>
          )}

          {!onlyForCreate && (
            <Paginator
              page={
                Math.ceil(
                  Number(filterParams?.[activePlatform].offset) /
                    Number(filterParams?.[activePlatform].limit),
                ) + 1
              }
              count={tenantRecipients?.[activePlatform].total || 0}
              limit={filterParams?.[activePlatform].limit}
              onChange={changePage}
            />
          )}
        </div>
      </div>
      <EasyModal
        withoutFooter
        withoutContentPadding
        label={recipientsTranslation('hash_modal_label')}
        variant="mediumW650"
        show={!!telegramHash}
        onClose={() => {
          if (!telegramHash) return;
          setTgHash(undefined);
          onClose();
        }}
      >
        <div className="flex items-center py-4 justify-center flex-col px-8 gap-[10px] w-full ">
          <span className="text-[12px] font-bold text-0color">
            <Trans
              i18nKey={recipientsTranslation('hash_modal_description', {
                tgLink: recipientsTranslation('tg_bot_link'),
              })}
              components={{
                botLink: (
                  <a
                    className="underline"
                    href={recipientsTranslation('tg_bot_link')}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                ),
                manualLink: <a href="https://ansp.cloudpbx.rt.ru/faq" className="underline"></a>,
              }}
            />
          </span>
          <div className="flex items-center  justify-between w-full">
            <div className="text-[11px] whitespace-pre-wrap text-0color">{telegramHash}</div>
            <div>
              <CircleButton
                onClick={() => {
                  if (!telegramHash) return;
                  // eslint-disable-next-line compat/compat
                  navigator?.clipboard
                    ?.writeText(telegramHash)
                    .then(() => toast.success(recipientsTranslation('copy_success')))
                    .catch(() => toast.error(recipientsTranslation('copy_error')));
                }}
                className="text-action"
                icon={'CopyIcon'}
              />
            </div>
          </div>
          <span className="text-[13px] font-[500] text-basic_red">
            {recipientsTranslation('hash_modal_key_description')}
          </span>
        </div>
      </EasyModal>
    </>
  );
};

export default AddRecipientsForm;
