import { Button, Input, Modal, TooltipWrapper } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';

import { NavLink } from 'react-router-dom';

import { v4 } from 'uuid';

import { XIcon } from '../ui/icons/icons-list';

export const WordsListComponent: React.FC<{
  currentDictionaryId?: string | undefined;
  wordsListArray: string[];
  setWordsListArray(word: string[]): void;
  dictionariesListArray: string[];
  setDictionariesListArray(word: string[]): void;
  className?: string;
  dictionaries?: DictionariesType[];
  variant?: 'wordsCloud' | 'default';
  withoutLabel?: boolean;
  scroll?: boolean;
  additionalMarkup?: ReactNode;
}> = ({
  wordsListArray,
  setWordsListArray,
  className,
  setDictionariesListArray,
  dictionariesListArray,
  currentDictionaryId,
  dictionaries,
  variant = 'default',
  additionalMarkup,
  withoutLabel = false,
  scroll = false,
}) => {
  const { t } = useTranslation('components.wordListComponent');
  const [dictionariesModal, setDictionariesModal] = React.useState(false);
  const [inputVisible, setInputVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [wordsListInput, setWordsListInput] = React.useState('');
  const [activeWord, setActiveWord] = React.useState('');
  const handleKeyDown = (e) => {
    if (e.target.id === 'mainContainer') {
      setInputVisible(true);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      if (inputVisible && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 0);
  }, [inputVisible]);
  const setNewWord = (event) => {
    if (event.key === 'Enter') {
      if (wordsListInput !== '' && !wordsListArray.includes(wordsListInput)) {
        setWordsListArray([...wordsListArray, wordsListInput]);
        return setWordsListInput('');
      } else {
        toast.error(t('word_err'));
      }
    }
  };

  const setNewDictionary = (id: string) => {
    if (!dictionariesListArray.includes(id)) {
      setDictionariesListArray([...dictionariesListArray, id]);
      setDictionariesModal(false);
    } else {
      toast.error(t('dict_err'));
    }
  };

  const deleteWord = (word: string) => {
    setWordsListArray(wordsListArray?.filter((e) => e !== word));
  };
  const deleteDictionary = (word: string) => {
    setDictionariesListArray(dictionariesListArray?.filter((e) => e !== word));
  };
  const onClickWord = (word: string) => {
    setDeleteVisible(true);
    setActiveWord(word);
  };
  const inputRef = React.useRef<HTMLInputElement>(null);

  const getDictionaryNameById = (id: string) => {
    return dictionaries?.find((e) => e.dictionary_id === id)?.name;
  };

  // const onDictionaryClick = (e: string) => {
  //   navigate(`/${navigationRoutes.dictionaries}/${e}/edit`);
  //   // setDictionariesListArray([]);
  //   // setWordsListArray([]);
  // };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const data = e.clipboardData.getData('text/html');
    const parser = new DOMParser()
      .parseFromString(data, 'text/html')
      .body.getElementsByTagName('p');
    if (parser.length >= 2) {
      const arr: string[] = [];
      for (let i = 0; i < parser.length; i++) {
        arr.push(parser?.item(i)?.innerText as string);
      }
      const array = _.uniq([...wordsListArray, ...arr]);
      setWordsListArray(array);
      setInputVisible(false);
      setWordsListInput('');
    }
  };
  return (
    <div className="flex justify-start flex-col">
      {!withoutLabel && (
        <div className="mb-[12px] flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('word_for_search')}</span>
        </div>
      )}
      <div
        id="mainContainer"
        onMouseDown={(e) => handleKeyDown(e)}
        className={`${className || ''} ${
          scroll ? 'overflow-y-scroll' : 'truncate'
        } w-full min-h-[360px] flex items-start  border-[#DCE0E5] border-solid border-[1px] p-[10px] rounded-[10px]`}
      >
        <div id="mainContainer" className="flex flex-wrap  items-center gap-[6px] w-full truncate">
          {dictionariesListArray.map((dictionary, index) => {
            return (
              <div
                className="flex truncate items-center p-[2px_16px_3px] relative bg-[#FFE2F4] text-0color underline text-[13px] rounded-[4px] gap-[4px] z-10"
                onMouseMove={() => onClickWord(dictionary)}
                onMouseOut={() => setDeleteVisible(false)}
                key={index}
              >
                <NavLink
                  to={`/${navigationRoutes.dictionaries}/${dictionary}/edit`}
                  // onClick={() => onDictionaryClick(dictionary)}
                  target={'_blank'}
                  className="cursor-pointer truncate"
                >
                  <TooltipWrapper
                    className="flex relative"
                    content={t('popup_hints.to_dictionary')}
                    id={v4()}
                  >
                    <span className="truncate">{getDictionaryNameById(dictionary)}</span>
                  </TooltipWrapper>
                </NavLink>
                {deleteVisible && activeWord === dictionary && (
                  <div
                    onClick={() => deleteDictionary(dictionary)}
                    className="bg-[#fff] p-[1.5px_2px] absolute right-[3px] rounded-full flex items-center justify-center"
                  >
                    <XIcon
                      hintTitle={t('popup_hints.delete_icon')}
                      className="text-basic_red cursor-pointer"
                      size={7}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {wordsListArray.map((word, index) => {
            return (
              <div
                onMouseMove={() => onClickWord(word)}
                onMouseOut={() => setDeleteVisible(false)}
                key={index}
                className="p-[2px_16px_3px] truncate relative flex items-center gap-[4px] bg-defaultBg text-0color text-[13px] rounded-[4px] z-10"
              >
                <span className="truncate">{word}</span>
                {deleteVisible && activeWord === word && (
                  <div
                    onClick={() => deleteWord(word)}
                    className="bg-[#fff] p-[1.5px_2px] absolute right-[3px] rounded-full flex items-center justify-center"
                  >
                    <XIcon
                      hintTitle={t('popup_hints.delete_icon')}
                      className="text-basic_red cursor-pointer"
                      size={7}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {inputVisible && (
            <div className="flex items-center gap-[6px]">
              <div
                tabIndex={-1}
                onBlur={() => {
                  if (wordsListInput !== '' && !wordsListArray.includes(wordsListInput)) {
                    setWordsListArray([...wordsListArray, wordsListInput]);
                    return setWordsListInput('');
                  } else if (wordsListArray.includes(wordsListInput)) {
                    toast.error(t('word_err'));
                  }
                  setInputVisible(false);
                }}
                onKeyDown={(event) => setNewWord(event)}
              >
                <Input
                  ref={inputRef}
                  defaultValue={wordsListInput}
                  onChange={(e) => setWordsListInput(e.target.value)}
                  onPaste={handlePaste}
                  type={'text'}
                  name={'wordList'}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col  ml-auto items-end ">
          {variant !== 'wordsCloud' && (
            <div onClick={() => setDictionariesModal(true)} className="">
              <Button
                hintTitle={t('popup_hints.add_dictionary_from_list')}
                fill="linked"
                label="+"
                icon={'BookIcon'}
                className={'!px-[3px]'}
              />
            </div>
          )}
          {additionalMarkup ? additionalMarkup : <></>}
        </div>
      </div>
      <Modal
        id={'Dictionaries'}
        value={dictionariesModal}
        setValue={setDictionariesModal}
        title={<div className="w-full flex justify-center">{t('dict')}</div>}
        noFooter
        size="md"
      >
        {
          <ul className="flex rounded-[12px] overflow-x-hidden flex-col border-[1px] border-[#F5F5F7]">
            {dictionaries
              ?.filter((e) => e.dictionary_id !== currentDictionaryId)
              .map((type) => {
                return (
                  <li
                    className="w-full items-center justify-between flex border-b-[1px] border-[#F5F5F7] p-[10px] text-1color text-[17px] h-[64px]"
                    key={type.dictionary_id}
                  >
                    <button
                      onClick={() => setNewDictionary(type.dictionary_id)}
                      type="button"
                      data-id={`dictionary-list-${type.name}`}
                      className={`flex w-full items-center justify-between`}
                    >
                      <div className="truncate">{type.name}</div>
                      <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                    </button>
                  </li>
                );
              })}
          </ul>
        }
      </Modal>
    </div>
  );
};
